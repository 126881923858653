export const GET_USER = 'GET_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const USER_ERROR = 'USER_ERROR';
export const USER_RESPONSE = 'USER_RESPONSE';

const apiUrl = process.env.REACT_APP_BE_URL;

export function getUserAction(user) {
	return {
		type: GET_USER,
		payload: user,
	};
}
/**
 *
 * @param {string} data containing error
 * @returns {void}
 */
export const setUserError = (data) => {
	return {
		type: USER_ERROR,
		payload: data,
	};
};

export const setUserResponse = (response) => {
	return {
		type: USER_RESPONSE,
		payload: response,
	};
};

export const fetchUser = async (token) => {
	try {
		const res = await fetch(apiUrl + '/users', {
			method: 'GET',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

export const getUser = (token) => {
	return (dispatch) => {
		fetchUser(token).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setUserError(resJSON.error));
				} else {
					localStorage.setItem('username', resJSON.user.username);
					dispatch(getUserAction(resJSON.user));
				}
			} else {
				console.error(resJSON);
				dispatch(setUserError(resJSON));
			}
		});
	};
};
