import React, { useState } from 'react';
import SidebarBox from '../SidebarBox/SidebarBox';
import VideoUpload from '../VideoUpload/VideoUpload';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import ProgressBar from '../ProgressBar/ProgressBar';
import RichText from '../RichText/RichText';
import { useTranslation } from '../../utils/useTranslation';
import styles from './VideoBox.module.scss';
import { ReactComponent as DoneIco } from '../../assets/icons/done.svg';

function VideoBox(props) {
	const t = useTranslation();
	const [progress, setProgress] = useState(0);
	const [isUploading, setIsUploading] = useState(false);
	const [isVideoUploaded, setVideoUploaded] = useState(false);
	const [videoName, setVideoName] = useState('');

	function getProgress(progress) {
		if (progress > 0 && progress < 100) {
			setIsUploading(true);
		} else {
			setIsUploading(false);
		}
		if (progress === 100) {
			setVideoUploaded(true);
			setIsUploading(false);
		}
		setProgress(progress);
	}

	function getVideoName(videoName) {
		setVideoName(videoName);
	}

	return (
		<SidebarBox>
			<div>
				<div className={styles.inputGroup}>
					<label className={styles.label}>{t.sceneTab.description}</label>
					<RichText
						selectedScene={props.selectedScene}
						onBlur={() =>
							props.dispatch(
								props.updateScene(props.token, props.selectedScene._id, { richText: props.selectedScene.richText })
							)
						}
						onChange={(e) => {
							props.dispatch(props.updateSceneProp(props.selectedScene._id, 'richText', e));
						}}
						defaultValue={props.selectedScene.richText}
					/>
				</div>
				
				<div className={styles.inputGroup}>
					{isVideoUploaded && props.selectedScene.videoFileUrl ? (
						<div className={styles.videoUploadedBox}>
							<span className={styles.videoUploadedLabel}>{videoName}</span>
							<DoneIco className={styles.doneIcon} />
						</div>
					) : (
						<div>
							<label className={styles.addVideoLabel}>{t.videoTemplate.addVideo}</label>
							{isUploading === false && (
								<div className={styles.imageRow}>
									<VideoUpload
										dispatch={props.dispatch}
										updateScene={props.updateScene}
										token={props.token}
										selectedScene={props.selectedScene}
										updateSceneProp={props.updateSceneProp}
										getProgress={getProgress}
										isUploading={isUploading}
										getVideoName={getVideoName}
									/>
								</div>
							)}
							{progress > 0 && progress < 100 && (
								<div className={styles.uploadingContainer}>
									<h6 className={styles.uploadFileLabel}>
										{t.videoUpload.uploadingFile} {parseInt(progress)}%
									</h6>
									<ProgressBar percentage={progress} />
								</div>
							)}
						</div>
					)}
				</div>

				{props.selectedScene.videoFileUrl ? (
					<div>
						<h2 className={styles.title}>{props.selectedScene.videoFileName}</h2>
						<div className={styles.inputGroup}>
							<div className={styles.imageRow}>
								<VideoPlayer videoUrl={props.selectedScene.videoFileUrl} sidebar={true} />
							</div>
						</div>						
					</div>
				) : (
					<div></div>
				)}
			</div>
		</SidebarBox>
	);
}

export default VideoBox;
