import React from 'react';
import axios from 'axios';
import upload from '../../assets/icons/upload.svg';
import 'react-loader-spinner-svg/dist/loader/css/react-spinner-loader.css';
import styles from './VideoUpload.module.scss';
import { showModal, hideModal } from '../../actions/modalActions';
import { useTranslation } from '../../utils/useTranslation';
import { useDispatch } from 'react-redux';
import { fetchDeleteCloudinaryVideo } from '../../actions/scenesActions';
import { MAX_VIDEO_UPLOAD_SIZE_BYTES } from '../../utils/constants/maxUploadSize';

const ENVIRONMENT = process.env.REACT_APP_CURRENT_ENV;
const CLOUDINARY_PROD_PRESET = process.env.REACT_APP_CLOUDINARY_PROD_PRESET;
const CLOUDINARY_DEV_PRESET = process.env.REACT_APP_CLOUDINARY_DEV_PRESET;
const CLOUDINARY_CLOUD_NAME = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;

function VideoUpload(props) {
	const t = useTranslation();
	const dispatch = useDispatch();

	function showPopup(title) {
		dispatch(
			showModal('POPUP_MODAL', {
				title: title,
				buttonPrimary: {
					label: t.popup.okay,
					action: () => {
						dispatch(hideModal());
					},
				},
			})
		);
	}

	function checkFileExtension(fileName) {
		let fileExtension = fileName.split('.').pop();

		const SUPPORTED_EXTENSION_MP4 = 'mp4';
		const SUPPORTED_EXTENSION_MOV = 'mov';

		if (fileExtension !== SUPPORTED_EXTENSION_MP4 && fileExtension !== SUPPORTED_EXTENSION_MOV) {
			showPopup(
				`${fileExtension} ${t.videoUpload.wrongExtension} ${SUPPORTED_EXTENSION_MP4} & ${SUPPORTED_EXTENSION_MOV}`
			);
			return false;
		} else {
			return true;
		}
	}

	function handleChange(event) {
		let file = event.target.files[0];

		if (!checkFileExtension(file.name)) return

		props.getVideoName(file.name);
		let reader = new FileReader();

		if (file.size > MAX_VIDEO_UPLOAD_SIZE_BYTES) {
			showPopup(t.videoUpload.videoMaxSizeTitle);
		} else {
			reader.addEventListener(
				'load',
				async function () {
					const formData = new FormData();
					//We need to convert our video file to base64 so we can upload it
					const base = await toBase64(file);
					formData.append('file', base);
					if (ENVIRONMENT === 'development') {
						formData.append('upload_preset', CLOUDINARY_DEV_PRESET);
					} else {
						formData.append('upload_preset', CLOUDINARY_PROD_PRESET);
					}
					formData.append('cloud_name', CLOUDINARY_CLOUD_NAME);
					formData.append('resource_type', 'video');

					let cloudinaryUploadURL = 'https://api.cloudinary.com/v1_1/' + CLOUDINARY_CLOUD_NAME + '/upload';
					let requestObj = {
						url: cloudinaryUploadURL,
						method: 'POST',
						data: formData,
						onUploadProgress: function (progressEvent) {
							//Calculate percentage and send it to the parent component (VideoBox)
							props.getProgress((progressEvent.loaded * 100) / progressEvent.total);
						},
					};

					const oldVideoId = props.selectedScene?.videoFilePublicId;
					axios(requestObj)
						.then((res) => {
							const url = res.data.secure_url;
							const token = props.token;
							const scene = props.selectedScene;
							const sceneID = scene._id;
							scene.videoFileUrl = url;
							scene.videoFileName = file.name;
							scene.videoFilePublicId = res.data.public_id;
							props.dispatch(props.updateScene(token, sceneID, scene));
						})
						.catch((error) => console.log(error))
						.finally(async () => {
							oldVideoId && await fetchDeleteCloudinaryVideo(props.token, oldVideoId)
						});
				},
				false
			);
			if (file && file.name) {
				reader.readAsDataURL(file);
			}
		}
	}

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	return (
		<React.Fragment>
			<div>
				{props.isUploading && !props.selectedScene.videoFileUrl ? (
					<div></div>
				) : (
					<div>
						<div className={styles.uploadBox}>
							<div className={styles.uploadWrapper}>
								<img src={upload} alt="uploadIco" className={styles.uploadIcon} />
								<input
									className={styles.videoInput}
									type="file"
									id="video"
									name="video"
									accept=".mp4, .mov"
									onChange={(event) => handleChange(event)}
								/>
								<span className={styles.inputText}>{t.videoUpload.dragAndDrop}</span>
							</div>
						</div>
						<p className={styles.videoExtensions}>{t.videoUpload.fileType}</p>
					</div>
				)}
			</div>
		</React.Fragment>
	);
}

export default VideoUpload;
