import { React, useEffect, useState } from 'react';

import classNames from 'classnames';

import SidebarBox from '../SidebarBox/SidebarBox';
import Input from '../Input/Input';
import SelectInput from '../SelectInput/SelectInput';
import { useTranslation } from '../../utils/useTranslation';

import styles from './SceneBox.module.scss';
import { ReactComponent as PenIcon } from '../../assets/icons/pen-mini.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ifUserRole } from '../../utils/ifUserRole';
import { ROLES } from '../../utils/constants/roles';
import { updateScene } from '../../actions/scenesActions';

// Renders the dropdown, the question and the first-scene checkbox
// Called by SceneTab.js
function SceneBox(props) {
	const t = useTranslation();
	const dispatch = useDispatch();
	const [showInput, setShowInput] = useState(false);
	const user = useSelector((state) => state.user);
	const scenes = props.scenes;

	useEffect(() => {
		if (showInput) {
			document.getElementById('sceneTitle').focus();
		}
		document.documentElement.setAttribute('data-color-mode', 'light'); // this one sets light mode on for <CodeEditor>
	}, [showInput]);

	function setFirstScene(isChecked) {
		props.selectedScene.isFirstScene = isChecked;
		return props.selectedScene;
	}

	function checkAnyOtherFirstScene() {
		for (let scene of scenes) {
			if (scene._id === props.selectedScene._id) {
				continue;
			}
			if (scene.isFirstScene) {
				scene.isFirstScene = false;
				dispatch(updateScene(props.token, scene._id, scene));
			}
		}
	}

	function updateSceneTitle () {
			props.dispatch(
				props.updateScene(props.token, props.selectedScene._id, { title: props.selectedScene.title })
			);
			setShowInput(false);
	}

	const SceneTitle = () => {
		return showInput ? (
			<input
				id="sceneTitle"
				type="text"
				value={props.selectedScene && props.selectedScene.title}
				name="title"
				onChange={(e) => {
					if (e.target.value.length <= 12) {
						props.dispatch(props.updateSceneProp(props.selectedScene._id, e.target.name, e.target.value));
					}
				}}
				onBlur={() => updateSceneTitle()}
				className={styles.titleInput}
				autoFocus
			/>
		) : (
			<h2 className={styles.title}>
				{props.selectedScene?.title}
				<PenIcon
					alt="PenIcon"
					className={styles.icon}
					onClick={() => {
						setShowInput(true);
					}}
				/>
			</h2>
		)
	}

	return (
		<SidebarBox>
			<div className={styles.inputGroup}>
				<SceneTitle />
			</div>
			<div className={classNames(styles.inputGroup, styles.zIndex)}>
				{/* <label className={styles.label}>{t.sceneTab.template}</label> */}
				<SelectInput
					options={[
						{
							value: 'text',
							label: t.sceneTab.question,
							onClick: () => {
								props.dispatch(
									props.updateScene(props.token, props.selectedScene._id, {
										template: 'text',
										answers:
											props.selectedScene.answers.length === 0
												? {
														answer: t.template.choose,
														sceneId: '',
												  }
												: props.selectedScene.answers.length <= 4
												? props.selectedScene.answers.slice(0, props.selectedScene.answers.length).map((answ) => {
														return {
															...answ,
															answer: answ.answer,
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
												  })
												: props.selectedScene.answers.length > 4
												? props.selectedScene.answers.slice(0, 4).map((answ) => {
														return {
															...answ,
															answer: t.template.choose,
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
												  })
												: props.selectedScene.answers.map((answ) => {
														return {
															...answ,
															answer: t.template.choose,
															sceneId: '',
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
												  })[0],
										key: Math.random() * 100000,
									})
								);
							},
						},
						{
							value: 'text_image',
							label: t.sceneTab.questionWithPicture,
							onClick: () => {
								props.dispatch(
									props.updateScene(props.token, props.selectedScene._id, {
										template: 'text_image',
										answers:
											props.selectedScene.answers.length === 0
												? {
														answer: t.template.choose,
														sceneId: '',
												  }
												: props.selectedScene.answers.length <= 4
												? props.selectedScene.answers.slice(0, props.selectedScene.answers.length).map((answ) => {
														return {
															...answ,
															answer: answ.answer,
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
												  })
												: props.selectedScene.answers.length > 4
												? props.selectedScene.answers.slice(0, 4).map((answ) => {
														return {
															...answ,
															answer: t.template.choose,
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
												  })
												: props.selectedScene.answers.map((answ) => {
														return {
															...answ,
															answer: t.template.choose,
															sceneId: '',
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
												  })[0],
										key: Math.random() * 100000,
									})
								);
							},
						},
						/* {
							value: 'video',
							label: t.sceneTab.templateValue3,
							onClick: () => {
								props.dispatch(
									props.updateScene(props.token, props.selectedScene._id, {
										template: 'video',
										key: Math.random() * 100000,
									})
								);
							},
						}, */
						{
							value: 'images',
							label: t.sceneTab.imageSelection,
							onClick: () => {
								props.dispatch(
									props.updateScene(props.token, props.selectedScene._id, {
										template: 'images',
										answers:
											props.selectedScene.answers.length === 0
												? {
														answer: t.template.choose,
														sceneId: '',
												  }
												: props.selectedScene.answers.length <= 4
												? props.selectedScene.answers.slice(0, props.selectedScene.answers.length).map((answ) => {
														return {
															...answ,
															answer: answ.answer,
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
												  })
												: props.selectedScene.answers.map((answ) => {
														return {
															...answ,
															answer: t.template.choose,
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
												  }),
										key: Math.random() * 100000, //ANSWERS WONT UPDATE PROPERLY WITHOUT THIS
									})
								);
							},
						},
						{
							value: 'columns',
							label: t.sceneTab.columns,
							onClick: () => {
								props.dispatch(
									props.updateScene(props.token, props.selectedScene._id, {
										template: 'columns',
										answers:
											props.selectedScene.answers.length === 0
												? {
														answer: t.template.choose,
														sceneId: '',
												  }
												: props.selectedScene.answers.length <= 4
												? props.selectedScene.answers.slice(0, props.selectedScene.answers.length).map((answ) => {
														return {
															...answ,
															answer: answ.answer,
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
												  })
												: props.selectedScene.answers.length > 4
												? props.selectedScene.answers.slice(0, 4).map((answ) => {
														return {
															...answ,
															answer: t.template.choose,
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
												  })
												: props.selectedScene.answers.map((answ) => {
														return {
															...answ,
															answer: t.template.choose,
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
												  }),
										key: Math.random() * 100000, //ANSWERS WONT UPDATE PROPERLY WITHOUT THIS
									})
								);
							},
						},
						{
							value: 'contact',
							label: t.sceneTab.contactForm,
							onClick: () => {
								props.dispatch(
									props.updateScene(props.token, props.selectedScene._id, {
										template: 'contact',
										contact: {
											name: {
												value: true,
												name: t.sceneTab.contactSection.name,
											},
											surname: {
												value: true,
												name: t.sceneTab.contactSection.surname,
											},
											email: {
												value: true,
												name: t.sceneTab.contactSection.email,
											},
											telephone: {
												value: true,
												name: t.sceneTab.contactSection.phone,
											},
											other: {
												value: false,
												name: t.sceneTab.contactSection.other,
											},
											tos: true,
											tosLink: t.template.acceptTOS,
											emailLink: user.email,
										},
										answers:
											props.selectedScene.answers.length === 0
												? {
														answer: t.template.send,
														sceneId: '',
												  }
												: props.selectedScene.answers.map((answ) => {
														return {
															...answ,
															answer: t.template.send,
															sceneId: '',
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
												  })[0],
										key: Math.random() * 100000,
									})
								);
							},
						},
						ifUserRole(ROLES.ADMIN, ROLES.PROFESSIONAL) && { value: 'contactPro',
							label: t.sceneTab.contactFormPro,
							onClick: () => {
								props.dispatch(
									props.updateScene(props.token, props.selectedScene._id, {
										template: 'contactPro',
										contactPro: {
											emailLink: user.email,
										},
										answers:
											props.selectedScene.answers.length === 0
												? {
														answer: t.template.send,
														sceneId: '',
												  }
												: props.selectedScene.answers.map((answ) => {
														return {
															...answ,
															answer: t.template.send,
															sceneId: '',
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
												  })[0],
										key: Math.random() * 100000,
									})
								);
							},
						},
						ifUserRole(ROLES.ADMIN, ROLES.PROFESSIONAL) && {
							value: 'video',
							label: t.sceneTab.video,
							onClick: () => {
								props.dispatch(
									props.updateScene(props.token, props.selectedScene._id, {
										template: 'video',
										answers:
											props.selectedScene.answers.length === 0
												? {
														answer: t.template.choose,
														sceneId: '',
												  }
												: props.selectedScene.answers.length <= 4
												? props.selectedScene.answers.slice(0, props.selectedScene.answers.length).map((answ) => {
														return {
															...answ,
															answer: answ.answer,
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
												  })
												: props.selectedScene.answers.length > 4
												? props.selectedScene.answers.slice(0, 4).map((answ) => {
														return {
															...answ,
															answer: t.template.choose,
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
												  })
												: props.selectedScene.answers.map((answ) => {
														return {
															...answ,
															answer: t.template.choose,
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
												  }),

										key: Math.random() * 100000, //ANSWERS WONT UPDATE PROPERLY WITHOUT THIS
									})
								);
							},
						},
						ifUserRole(ROLES.ADMIN, ROLES.PROFESSIONAL) && { value: 'json',
							label: t.sceneTab.json,
							onClick: () => {
								props.dispatch(
									props.updateScene(props.token, props.selectedScene._id, {
										template: 'json',
										answers: [{ answer: 'Ja' }],
										key: Math.random() * 100000,
									})
								);
							},
						},
						ifUserRole(ROLES.ADMIN, ROLES.PROFESSIONAL) && { value: 'freeText',
							label: t.addTab.freeText,
							onClick: () => {
								props.dispatch(
									props.updateScene(props.token, props.selectedScene._id, {
										template: 'freeText',
										freeText: {
											description: '',
											inputType: 'text',
											placeholder: t.sceneTab.placeholder,
											required: false,
											defaultAnswer: t.sceneTab.noAnswer
										},
										answers: { answer: t.jsonTemplate.continue },
										key: Math.random() * 100000,
									})
								);
							},
						},
						ifUserRole(ROLES.ADMIN) ? 
							{ value: 'html',
								label: t.sceneTab.html,
								onClick: () => {
									props.dispatch(
										props.updateScene(props.token, props.selectedScene._id, {
											template: 'html',
											answers:
											props.selectedScene.answers.length === 0
												? {
														answer: t.template.choose,
														sceneId: '',
													}
												: props.selectedScene.answers.length <= 4
												? props.selectedScene.answers.slice(0, props.selectedScene.answers.length).map((answ) => {
														return {
															...answ,
															answer: answ.answer,
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
													})
												: props.selectedScene.answers.length > 4
												? props.selectedScene.answers.slice(0, 4).map((answ) => {
														return {
															...answ,
															answer: t.template.choose,
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
													})
												: props.selectedScene.answers.map((answ) => {
														return {
															...answ,
															answer: t.template.choose,
															imageTitle: '',
															richText: '',
															price: '',
															imageUrl: '',
														};
													}),
										key: Math.random() * 100000, //ANSWERS WONT UPDATE PROPERLY WITHOUT THIS
										})
									);
								},
							}
						: { onClick: () => {} }, //there is a empty space in dropdown and if you press the appliaction will crash without this line
					]}
					selected={props.selectedScene ? props.selectedScene.template : 'json'}
					ternary
					noScroll
					disabled={!props.selectedScene._id}
				/>
			</div>
			<div className={styles.inputGroup}>
				<label className={styles.label}>{t.sceneTab.question}</label>
				<Input
					type="textarea"
					value={props.selectedScene && props.selectedScene.question}
					name="question"
					onChange={(e) => {
						if (e.target.value.length <= 100) {
							props.dispatch(props.updateSceneProp(props.selectedScene._id, e.target.name, e.target.value));
						}
					}}
					onBlur={() =>
						props.dispatch(
							props.updateScene(props.token, props.selectedScene._id, { question: props.selectedScene.question })
						)
					}
					secondary
					rows="3"
					error={props.selectedScene && props.selectedScene.question.length >= 100 && t.sceneTab.errors.question}
					errorstyle={{ top: '78px', left: '15px', textAlign: 'left', fontSize: '12px' }}
				/>
			</div>
			<div className={styles.checkboxWrapper}>
				<input
					type="checkbox"
					name="isFirstScene"
					checked={props.selectedScene && props.selectedScene.isFirstScene}
					onChange={(e) => {
						dispatch(updateScene(props.token, props.selectedScene._id, setFirstScene(e.target.checked)));
						checkAnyOtherFirstScene();
					}}
				/>
				<label className={styles.checkboxLabel}>{t.sceneTab.setFirstScene}</label>
			</div>
		</SidebarBox>
	);
}

export default SceneBox;