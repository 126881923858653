import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedTemplate } from '../../actions/addTabActions';
import { setActiveTab } from '../../actions/sidebarActions';
import AddSceneTab from '../AddSceneTab/AddSceneTab';

import SceneTab from '../SceneTab/SceneTab';
import GeneralTab from '../GeneralTab/GeneralTab';

import styles from './Sidebar.module.scss';
import CogIco from '../../assets/icons/cog.svg';
import PenIco from '../../assets/icons/pen.svg';
import RectIco from '../../assets/icons/rect.svg';

// called by SceneView.js
function Sidebar(props) {
	const dispatch = useDispatch();
	const activeTab = useSelector((state) => state.sidebar.activeTab);
	const { selectedSceneId, setSelectedSceneId } = props;

	useEffect(() => {
		if (selectedSceneId) {
			dispatch(setActiveTab('scene'));
		} else {
			dispatch(setActiveTab('add'));
		}
	}, [selectedSceneId, dispatch]);

	function renderActiveTab() {
		switch (activeTab) {
			case 'scene':
				dispatch(setSelectedTemplate(null));
				return <SceneTab selectedSceneId={selectedSceneId} setSelectedSceneId={setSelectedSceneId} />;
			case 'general':
				dispatch(setSelectedTemplate(null));
				return <GeneralTab projectId={props.projectId} />;
			default:
				return <AddSceneTab />;
		}
	}
	return (
		<div className={styles.sidebar}>
			<ul className={styles.tabs}>
				<li
					className={classNames(styles.tab, activeTab === 'add' && styles.active)}
					onClick={() => dispatch(setActiveTab('add'))}
				>
					<img src={RectIco} alt="PlusIcon" />
				</li>
				<li
					className={classNames(styles.tab, {
						[styles.active]: activeTab === 'scene',
					})}
					onClick={() => {
						if (props.selectedSceneId) dispatch(setActiveTab('scene'));
					}}
				>
					<img src={PenIco} alt="SceneIcon" />
				</li>
				<li
					className={classNames(styles.tab, { [styles.active]: activeTab === 'general' })}
					onClick={() => dispatch(setActiveTab('general'))}
				>
					<img src={CogIco} alt="GeneralIcon" />
				</li>
			</ul>
			<div className={styles.container}>{renderActiveTab()}</div>
		</div>
	);
}

export default Sidebar;
