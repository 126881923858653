import { useDispatch } from 'react-redux';
import ImageUpload from '../ImageUpload/ImageUpload';
import SidebarBox from '../SidebarBox/SidebarBox';
import Toggle from '../Toggle/Toggle';
import { useTranslation } from '../../utils/useTranslation';
import { ifUserRole } from '../../utils/ifUserRole';
import { ROLES } from '../../utils/constants/roles';
import styles from './ImageBox.module.scss';


/* Called by GeneralTab.js */
function ImageBox(props) {
	const t = useTranslation();
	const dispatch = useDispatch();
	const selectedProject = props.selectedProject;
	const token = props.token;

	return (
		<SidebarBox>
			<div className={styles.inputGroup}>
				<label className={styles.label}>{props.label}</label>
				<div className={styles.imageRow}>
					<ImageUpload
						onUpload={(newImageUrl, imagePublicId) => 					
							dispatch(
								props.updateProject(token, {
									...selectedProject,
									general: { ...selectedProject.general, previewImage: newImageUrl, imagePublicId },
								})
						)}
						imageUrl={selectedProject?.general?.previewImage}
						index={props.index}
						token={token}
					/>
					<span className={styles.condition}>{t.imageUpload.previewImageSize}</span>
				</div>
				
				{ifUserRole(ROLES.ADMIN) && <div className={styles.inputToggle}>
					<label className={styles.label}>{t.imageUpload.retrieveOrigSize}</label>
					<Toggle
						checked={selectedProject.general.retrieveOrigSize}
						onChange={() => 					
							dispatch(
								props.updateProject(token, {
									...selectedProject,
									general: { ...selectedProject.general, retrieveOrigSize: !selectedProject.general.retrieveOrigSize },
								})
						)}
					/>
					<label className={styles.label}>{t.generalTab.yes}</label>
				</div>}

			</div>
		</SidebarBox>
	);
}

export default ImageBox;
