import React from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import Input from '../../components/Input/Input';
import SelectInput from '../../components/SelectInput/SelectInput';
import Toggle from '../../components/Toggle/Toggle';
import ImageUpload from '../../components/ImageUpload/ImageUpload';
import RichText from '../RichText/RichText';
import { showModal, hideModal } from '../../actions/modalActions';
import { useTranslation } from '../../utils/useTranslation';
import { validateUrl } from '../../utils/validateUrl';

import styles from './Answer.module.scss';
import { ReactComponent as MinusIco } from '../../assets/icons/minus-circle.svg';
import { ifUserRole } from '../../utils/ifUserRole';
import { ROLES } from '../../utils/constants/roles';

const MAX_ANSWER_BUTTON_CHARACTER_LENGTH = 24

function Answer(props) {
	const t = useTranslation();
	const dispatch = useDispatch();

	let maxAnswerBtnCharLength = MAX_ANSWER_BUTTON_CHARACTER_LENGTH
	if (ifUserRole(ROLES.ADMIN)) maxAnswerBtnCharLength = 1000

	function showPopup() {
		dispatch(
			showModal('POPUP_MODAL', {
				title: t.sceneTab.minOneAnswer,
				buttonPrimary: {
					label: t.popup.ok,
					action: () => {
						dispatch(hideModal());
					},
				},
			})
		);
	}

	function renderAnswerRow(answer, index) {
		return (
			<div className={styles.answerGroup} key={index}>
				<div className={styles.answerInputContainer}>
					<label className={styles.answerLabel}>{t.sceneTab.answer}</label>
					<Input
						type="text"
						name="answer"
						value={answer.answer}
						onChange={(e) => {
							if (e.target.value.length <= maxAnswerBtnCharLength) {
								props.dispatch(props.updateSceneAnswer(props.selectedScene._id, index, e.target.name, e.target.value));
							}
						}}
						onBlur={() => {
							props.dispatch(props.updateAnswer(props.token, props.selectedScene._id, index, answer));
						}}
						secondary
						error={answer.answer.length >= maxAnswerBtnCharLength && t.sceneTab.errors.answer}
						errorstyle={{ fontSize: '12px' }}
					/>
				</div>
				<MinusIco
					className={styles.minusIco}
					onClick={() => {
						if (
							props.selectedScene.answers.length > 1 ||
							props.selectedScene.template === 'text' ||
							props.selectedScene.template === 'text_image' ||
							props.selectedScene.template === 'video'
						) {
							props.dispatch(props.removeAnswer(props.token, props.selectedScene._id, index));
						} else {
							showPopup();
						}
					}}
				/>
			</div>
		);
	}

	function renderLinkRow(answer, index) {
		return (
			<div className={styles.inputGroup}>
				{renderToggle(answer, index)}
				{renderLink(answer, index)}
			</div>
		);
	}

	function renderToggle(answer, index) {
		return (
			<div className={styles.inputToggle}>
				<label className={styles.label}>{t.sceneTab.scene}</label>
				<Toggle
					checked={answer.external}
					onChange={() => {
						props.dispatch(
							props.updateAnswer(props.token, props.selectedScene._id, index, {
								external: !answer.external,
								sceneId: '',
								url: '',
							})
						);
					}}
				/>
				<label className={styles.label}>{t.sceneTab.url}</label>
			</div>
		);
	}

	function renderScenesOptions(actionIndex) {
		const sceneOptions = props.scenes.map((scene) => {
			return {
				value: scene._id,
				label: scene.title,
				onClick: () => {
					props.dispatch(
						props.updateAnswer(props.token, props.selectedScene._id, actionIndex, {
							sceneId: scene._id,
						})
					);
				},
			};
		});
		sceneOptions.push({
			value: '',
			label: t.sceneTab.removeLink,
			onClick: () => {
				props.dispatch(
					props.updateAnswer(props.token, props.selectedScene._id, actionIndex, {
						sceneId: '',
					})
				);
			},
		});
		return sceneOptions;
	}

	function renderLink(answer, index) {
		if (answer.external) {
			return (
				<div className={classNames(styles.zIndex)}>
					<Input
						type="text"
						name="url"
						value={answer.url}
						onChange={(e) => {
							props.dispatch(props.updateSceneAnswer(props.selectedScene._id, index, e.target.name, e.target.value));
						}}
						onBlur={() => {
							if (validateUrl(answer.url)) {
								props.dispatch(
									props.updateAnswer(props.token, props.selectedScene._id, index, {
										url: answer.url,
									})
								);
							}
						}}
						placeholder="https://"
						ternary
						error={answer.url.length > 0 && !validateUrl(answer.url) && t.sceneTab.errors.link}
						errorstyle={{ fontSize: '12px' }}
					/>
				</div>
			);
		} else {
			return (
				<div className={classNames(styles.zIndex)}>
					<SelectInput options={renderScenesOptions(index)} selected={answer.sceneId} ternary />
				</div>
			);
		}
	}

	function renderImageRow(answer, index) {  // only for 'images' and 'columns' templates
		return (
			<React.Fragment>
				<div className={styles.inputGroup}>
					<label className={styles.label}>{t.sceneTab.image}</label>
					<div className={styles.imageRow}>
						<ImageUpload
							onUpload={(imageUrl, imagePublicId) => {
								props.dispatch(
									props.updateAnswer(props.token, props.selectedScene._id, index, {
										...answer, imageUrl: imageUrl, imagePublicId: imagePublicId
									})
								);
							}}
							imageUrl={answer.imageUrl}
							index={index}
							token={props.token}
						/>
						<span className={styles.condition}>{t.imageUpload.minMaxImageSize}</span>
					</div>
					<div className={styles.inputGroup}>
						<label className={styles.label}>{t.sceneTab.imageDescription}</label>
						<Input
						type="text"
						name="imageDescription"
						value={answer.imageDescription}
						onChange={(e) => props.dispatch(
							props.updateSceneAnswer(props.selectedScene._id, index, e.target.name, e.target.value)
						)}
						onBlur={() =>
							props.dispatch(
								props.updateAnswer(props.token, props.selectedScene._id, index, {
									imageDescription: answer.imageDescription,
								})
							)
						}
						secondary
						/>
					</div>
					{ifUserRole(ROLES.ADMIN) && <div className={styles.inputToggle}>
						<label className={styles.label}>{t.imageUpload.retrieveOrigSize}</label>
						<Toggle
							checked={answer.retrieveOrigSize}
							onChange={() => {
								props.dispatch(
									props.updateAnswer(props.token, props.selectedScene._id, index, {
										...answer, retrieveOrigSize: !answer.retrieveOrigSize
									})
								);
							}}
						/>
						<label className={styles.label}>{t.generalTab.yes}</label>
					</div>}
				</div>
				{props.selectedScene.template === 'columns' && (
					<>
						<div className={styles.inputGroup}>
							<label className={styles.label}>{t.sceneTab.title}</label>
							<Input
								type="text"
								name="imageTitle"
								value={answer.imageTitle}
								onChange={(e) => {
									if (e.target.value.length <= 15) {
										props.dispatch(
											props.updateSceneAnswer(props.selectedScene._id, index, e.target.name, e.target.value)
										);
									}
								}}
								onBlur={() => {
									props.dispatch(
										props.updateAnswer(props.token, props.selectedScene._id, index, {
											imageTitle: answer.imageTitle,
										})
									);
								}}
								secondary
								error={answer.imageTitle && answer.imageTitle.length >= 15 && t.sceneTab.errors.answer}
								errorstyle={{ fontSize: '12px' }}
							/>
						</div>
						<div className={styles.inputGroup}>
							<label className={styles.label}>{t.sceneTab.answerRichTextLabel}</label>
							<RichText
								selectedScene={props.selectedScene}
								onBlur={() => {
									props.dispatch(
										props.updateAnswer(props.token, props.selectedScene._id, index, {
											richText: answer.richText,
										})
									);
								}}
								onChange={(e) => {
									props.dispatch(props.updateSceneAnswer(props.selectedScene._id, index, 'richText', e));
								}}
								defaultValue={answer.richText}
							/>
						</div>
						<div className={styles.inputGroup}>
							<label className={styles.label}>{t.sceneTab.price}</label>
							<Input
								type="text"
								name="price"
								value={answer.price}
								onChange={(e) => {
									if (e.target.value.length <= 9) {
										props.dispatch(
											props.updateSceneAnswer(props.selectedScene._id, index, e.target.name, e.target.value)
										);
									}
								}}
								onBlur={() => {
									props.dispatch(
										props.updateAnswer(props.token, props.selectedScene._id, index, {
											price: answer.price,
										})
									);
								}}
								secondary
								error={answer.price && answer.price.length >= 9 && t.sceneTab.errors.answer}
								errorstyle={{ fontSize: '12px' }}
							/>
						</div>
					</>
				)}
			</React.Fragment>
		);
	}

	if (!props.answer) {
		return null;
	}

	return (
		<>
			<div className={styles.answer}>
				{renderAnswerRow(props.answer, props.index)}
				{renderLinkRow(props.answer, props.index)}
				{(props.selectedScene.template === 'columns' || props.selectedScene.template === 'images') &&
					renderImageRow(props.answer, props.index)}
			</div>
			<hr className={styles.hr} />
		</>
	);
}

export default Answer;
