import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getScenes } from '../../actions/scenesActions';
import Dashboard from '../../components/Dashboard/Dashboard';
import Sidebar from '../Sidebar/Sidebar';
import Canvas from '../Canvas/Canvas';

function SceneView() {
	const params = useParams();
	const dispatch = useDispatch();

	const token = useSelector((state) => state.auth.token);
	const scenes = useSelector((state) => state.scenes);
	const [selectedSceneId, setSelectedSceneId] = useState('');

	useEffect(() => {
		dispatch(getScenes(token, params.id));
	}, [dispatch, token, params.id]);

	return (scenes.data !== null) ? (
		<Dashboard>
			<Canvas
				scenesData={scenes.data}
				sceneKey={scenes.key}
				setSelectedSceneId={setSelectedSceneId}
				selectedSceneId={selectedSceneId}
			/>
			<Sidebar selectedSceneId={selectedSceneId} setSelectedSceneId={setSelectedSceneId} projectId={params.id} />
		</Dashboard>
	) : (
		(window.location.href = '/projectList')
	);
}

export default SceneView;
