
import SidebarBox from '../SidebarBox/SidebarBox';
import ImageUpload from '../ImageUpload/ImageUpload';
import RichText from '../RichText/RichText';
import Input from '../../components/Input/Input';
import Toggle from '../Toggle/Toggle';
import { ROLES } from '../../utils/constants/roles';
import { useTranslation } from '../../utils/useTranslation';
import { ifUserRole } from '../../utils/ifUserRole';
import styles from './ImageTextBox.module.scss';

/* Called by SceneTab.js for 'text_image', 'images', 'text' and 'json' templates. 
	 It renders the 'add picture', upload + conditions and alt meta tag for 'text_image' templates and
	 scene description and price for all templates listed above.
*/
function ImageTextBox(props) {
	const t = useTranslation();
	return (
		<SidebarBox>
			{props?.selectedScene?.template === 'text_image' && (
				<div className={styles.inputGroup}>
					<label className={styles.label}>{t.sceneTab.addImage}</label>
					<div className={styles.imageRow}>
						<ImageUpload
							onUpload={(imageUrl, imagePublicId) => {
								props.dispatch(props.updateScene(props.token, props.selectedScene._id, { imageUrl: imageUrl, imagePublicId: imagePublicId }));
							}}
							imageUrl={props.selectedScene.imageUrl}
							index={0}
							token={props.token}
						/>
						<span className={styles.condition}>{t.imageUpload.minMaxImageSize}</span>
					</div>

					<div className={styles.inputGroup}>
						<label className={styles.label}>{t.sceneTab.imageDescription}</label>
						<Input
						type="text"
						name="imageDescription"
						value={props.selectedScene.imageDescription}
						onChange={(e) => props.dispatch(props.updateSceneProp(props.selectedScene._id, e.target.name, e.target.value))}
						onBlur={() =>
							props.dispatch(
								props.updateScene(props.token, props.selectedScene._id, { imageDescription: props.selectedScene.imageDescription })
							)
						}
						secondary
						/>
					</div>

					{ifUserRole(ROLES.ADMIN) && <div className={styles.inputToggle}>
						<label className={styles.label}>{t.imageUpload.retrieveOrigSize}</label>
						<Toggle
							checked={props.selectedScene.retrieveOrigSize}
							onChange={() => {
								props.dispatch(
									props.updateScene(props.token, props.selectedScene._id, 
										{ retrieveOrigSize: !props.selectedScene.retrieveOrigSize })
								);
							}}
						/>
						<label className={styles.label}>{t.generalTab.yes}</label>
					</div>}

				</div>
			)}
			<div className={styles.inputGroup}>
				<label className={styles.label}>{t.sceneTab.description}</label>
				<RichText
					selectedScene={props.selectedScene}
					onBlur={() =>
						props.dispatch(
							props.updateScene(props.token, props.selectedScene._id, { richText: props.selectedScene.richText })
						)
					}
					onChange={(e) => {
						props.dispatch(props.updateSceneProp(props.selectedScene._id, 'richText', e));
					}}
					defaultValue={props.selectedScene.richText}
				/>
			</div>
			{props?.selectedScene?.template === 'text_image' && (
				<div className={styles.inputGroup}>
					<label className={styles.label}>{t.sceneTab.price}</label>
					<Input
						type="text"
						name="price"
						value={props.selectedScene.price}
						onChange={(e) => {
							if (e.target.value.length <= 10) {
								props.dispatch(props.updateSceneProp(props.selectedScene._id, 'price', e.target.value));
							}
						}}
						onBlur={() => {
							props.dispatch(
								props.updateScene(props.token, props.selectedScene._id, { price: props.selectedScene.price })
							);
						}}
						secondary
						error={props.selectedScene.price && props.selectedScene.price.length >= 10 && t.sceneTab.errors.answer}
						errorstyle={{ fontSize: '12px' }}
					/>
				</div>
			)}
		</SidebarBox>
	);
}

export default ImageTextBox;
