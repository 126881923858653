import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import Button from '../Button/Button';
import Input from '../../components/Input/Input';
import { showModal, hideModal } from '../../actions/modalActions';
import { useTranslation } from '../../utils/useTranslation';
import { getValidUrl } from '../../utils/getValidUrl';
import { validateEmail } from '../../utils/validateEmail';
import { lightenDarkenColor } from '../../utils/lightenDarkenColor';
import AudioPlayer from '../AudioPlayer/AudioPlayer';
import styles from './ContactTemplate.module.scss';
import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg';
import { templateBackground } from '../../utils/templateBackground';

const apiUrl = process.env.REACT_APP_BE_URL;

function ContactTemplate(props) {
	const t = useTranslation();
	const dispatch = useDispatch();
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [other, setOther] = useState('');
	const [acceptedTOS, setAcceptedTOS] = useState(false);
	const [firstNameError, setFirstNameError] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [phoneError, setPhoneError] = useState('');
	const [otherError, setOtherError] = useState('');
	const [acceptedTOSError, setAcceptedTOSError] = useState('');
	const [emailError, setEmailError] = useState('');
	// Are hoverIndex and hoverBack still needed?
	const [hoverIndex, setHoverIndex] = useState(false);
	const [hoverBack, setHoverBack] = useState(false);

	const contact = props.scene && props.scene.contact;
	const allHiddenFields = props?.project?.hiddenFields || [];
	// remove any empty key-value pairs
	const hiddenFields = allHiddenFields.filter((field) => field.key !== '' || field.value !== '');

	useEffect(() => setFirstNameError(''), [firstName]);
	useEffect(() => setLastNameError(''), [lastName]);
	useEffect(() => setEmailError(''), [email]);
	useEffect(() => setPhoneError(''), [phone]);
	useEffect(() => setOtherError(''), [other]);
	useEffect(() => setAcceptedTOSError(''), [acceptedTOS]);


	function getWindow(answer) {
		if (answer.external && answer.url) {
			return window.open();
		} else {
			return '';
		}
	}

	function showPopup(title, subtitle) {
		dispatch(
			showModal('POPUP_MODAL', {
				title: title,
				subtitle: subtitle,
				buttonPrimary: {
					label: t.popup.ok,
					action: () => {
						dispatch(hideModal());
					},
				},
			})
		);
	}

	function handleSubmit(answer) {
		if (!contact.emailLink) {
			showPopup(t.template.missingVendorEmail, t.template.contactSupport);
			return
		}
		let error = false;
		if (contact.name.value && firstName.length === 0) {
			error = true;
			setFirstNameError(t.login.fieldRequired);
		}
		if (contact.surname.value && lastName.length === 0) {
			error = true;
			setLastNameError(t.login.fieldRequired);
		}
		if (contact.email.value && email.length === 0) {
			error = true;
			setEmailError(t.login.fieldRequired);
		} else if (!validateEmail(email)) {
			error = true;
			setEmailError(t.login.emailInvalid);
		}
		if (contact.telephone.value && phone.length === 0) {
			error = true;
			setPhoneError(t.login.fieldRequired);
		}
		if (contact.other.value && other.length === 0) {
			error = true;
			setOtherError(t.login.fieldRequired);
		}
		if (contact.tos && !acceptedTOS) {
			error = true;
			setAcceptedTOSError(t.register.agreeToTOS);
		}
		if (!error) {

			let sourceUrl = window.location.href;
			let host = window.location.origin;
      let webhookURL = props?.project?.webhookURL || null;
			let data = {
        beraterId: props.project._id,
        beraterTitle:	props.project.title,
      	beraterAuthorId: props.project.userId,
				email,
				emailLabel: contact.email.name,
				firstName,
				firstNameLabel: contact.name.name,
				lastName,
				lastNameLabel: contact.surname.name,
				phone,
				phoneLabel: contact.telephone.name,
        beraterEmail: contact.emailLink,
        beraterCcEmail: contact.ccEmail,
        UTM: [],
        scenes: props.history.scenes ? props.history.scenes : null,
        projectName: props.history.projectName,
				history: props.history.history,
        host,
        sourceUrl,
        webhookURL,
        hiddenFields
      };
			if (props.scene.contact.other.name) {
				data.otherName = props.scene.contact.other.name;
				data.otherValue = other;
			}

			// props.getBeraterCompletedTime();
			const newTab = getWindow(answer);

			/* send data to backend to handle sending contact info via email and
         webhook and to save inquiry to database (if applicable)  */
			fetch(apiUrl + '/scene/contact', {
				method: 'POST',
				mode: 'cors',
				headers: {
					'Accept': 'application/json',
					'Content-type': 'application/json',
				},
				body: JSON.stringify({ data }),
			})
			.then( res => {
				if (res.status === 200) {
					//If there is no next scene or url
					if (!answer.sceneId && !answer.external) {
						showPopup(t.template.submissionSuccess);
					}
					//If there is next scene
					if (answer.sceneId) {
						props.goTo(answer.sceneId);
					}
					//If there is an url
					if (answer.external) {
						if (answer.url === '') {
							showPopup(t.template.submissionSuccess);
						}
						newTab.location.href = getValidUrl(answer.url);
					}
				} else {
					showPopup(t.template.submissionFailed, t.template.contactSupport);
				}
			})
			.catch((err) => showPopup(t.template.submissionFailed, t.template.contactSupport));

		}
	}

	/**
	 * TODO: Remove this after October 2022
	 * @return {JSX.Element}
	 */
	function renderWarning() {
		if (props.scene?.contact?.email === true) {
			return (
				<span className={styles.warning}>
					<p>
						Dieses Formular hat mehr Funktionen bekommen und muss gegen eine neue Kontaktszene ausgetauscht werden.
						<br />
						<br />
						Vielen Dank und viel Spaß mit den neuen Funktionen.
					</p>
				</span>
			);
		}
	}

	return (
		<React.Fragment>
			{props.showAudioPlayer && props.scene.audioFileUrl && (
				<AudioPlayer audioUrl={props.scene.audioFileUrl} autoplay={props.autoplay} />
			)}
			{props.backButton && props.firstSceneId !== props.scene._id.toString() && (
				<ChevronLeft
					className={styles.backButton}
					onClick={() => props.goBack()}
					style={{
						fill:
							props.project &&
							props.project.general &&
							props.project.general.buttonBackgroundColor &&
							props.project.general.buttonBackgroundColor.hex &&
							(hoverBack
								? lightenDarkenColor(props.project.general.buttonBackgroundColor.hex, -40)
								: props.project.general.buttonBackgroundColor.hex),
					}}
					onMouseEnter={() => setHoverBack(true)}
					onMouseLeave={() => setHoverBack(false)}
				/>
			)}
			<div
				className={classNames(styles.template)}
				style={templateBackground(props.project?.general?.backgroundImage, props.project?.general?.backgroundColor)}
			>
				{renderWarning()}
				<span className={classNames(styles.templateRow)}>
					{!props.hideSceneName && (
						<div className={styles.titleWrapper}>
							<span className={styles.title}>{props.scene && props.scene.title}</span>
						</div>
					)}
					<h1
						className={classNames(styles.question)}
						style={{
							color:
								props.project &&
								props.project.general &&
								props.project.general.color &&
								props.project.general.color.hex,
						}}
					>
						{props.scene && props.scene.question}{' '}
					</h1>
					<div className={styles.inputRow}>
						{props.scene.contact.name && props.scene.contact.name.value && (
							<div className={styles.inputWrapper}>
								<Input
									type="text"
									value={firstName}
									name="firstName"
									onChange={(e) => {
										setFirstName(e.target.value);
									}}
									squared
									template
									placeholder={props.scene.contact.name && props.scene.contact.name.name + '*'}
									error={firstNameError}
									// errorstyle={{ top: '35px', left: '0px', textAlign: 'center', fontSize: '7px' }}
								/>
							</div>
						)}
						{props.scene.contact.surname && props.scene.contact.surname.value && (
							<div
								className={classNames(styles.inputWrapper, {
									[styles.margin]: props.scene.contact.name && props.scene.contact.name.value,
								})}
							>
								<Input
									type="text"
									value={lastName}
									name="lastName"
									onChange={(e) => setLastName(e.target.value)}
									squared
									template
									placeholder={props.scene.contact.surname && props.scene.contact.surname.name + '*'}
									error={lastNameError}
									// errorstyle={{ top: '35px', left: '0px', textAlign: 'center', fontSize: '7px' }}
								/>
							</div>
						)}
					</div>
					<span className={styles.inputRow} style={{ display: 'block' }}>
						<Input
							type="text"
							value={email}
							name="email"
							onChange={(e) => setEmail(e.target.value)}
							squared
							template
							placeholder={props.scene.contact.email && props.scene.contact.email.name + '*'}
							error={emailError}
							// errorstyle={{ top: '35px', left: '0px', textAlign: 'center', fontSize: '7px' }}
						/>
					</span>
					<div className={styles.inputRow}>
						{props.scene.contact.telephone && props.scene.contact.telephone.value && (
							<div className={styles.inputWrapper}>
								<Input
									type="text"
									value={phone}
									name="phone"
									onChange={(e) => setPhone(e.target.value)}
									squared
									template
									placeholder={props.scene.contact.telephone && props.scene.contact.telephone.name + '*'}
									error={phoneError}
									// errorstyle={{ top: '35px', left: '0px', textAlign: 'center', fontSize: '7px' }}
								/>
							</div>
						)}
						{props.scene.contact.other && props.scene.contact.other.value && (
							<div
								className={classNames(styles.inputWrapper, {
									[styles.margin]: props.scene.contact.telephone && props.scene.contact.telephone.value,
								})}
							>
								<Input
									type="text"
									value={other}
									name="other"
									onChange={(e) => setOther(e.target.value)}
									squared
									template
									placeholder={props.scene.contact.other && props.scene.contact.other.name + '*'}
									error={otherError}
									// errorstyle={{ top: '35px', left: '0px', textAlign: 'center', fontSize: '7px' }}
								/>
							</div>
						)}
					</div>
					{props.scene.contact.tos && (
						<>
							<span className={styles.checkboxContainer} style={{ display: 'block' }}>
								<div className={styles.checkboxWrapper}>
									<input
										type="checkbox"
										checked={acceptedTOS}
										onChange={(e) => setAcceptedTOS(!acceptedTOS)}
										error={acceptedTOSError}
										// errorstyle={{ top: '38px', left: '0px', textAlign: 'center', fontSize: '7px' }}
									/>
									<div
										dangerouslySetInnerHTML={{ __html: props.scene.contact.tosLink }}
										className={styles.checkboxLink}
										style={{
											color:
												props.project &&
												props.project.general &&
												props.project.general.color &&
												props.project.general.color.hex,
										}}
									></div>
								</div>
								<div className={classNames(styles.error, { [styles.show]: acceptedTOSError })}>{acceptedTOSError}</div>
							</span>
						</>
					)}
					<span className={classNames(styles.buttonsContainer)} style={{ display: 'block' }}>
						{props.scene &&
							props.scene.answers.map((answer, index) => (
								<div
									className={styles.buttonWrapper}
									key={index}
									style={{ marginTop: props.scene.contact.tos && '8px' }}
								>
									<Button
										key={answer.answer + props.scene._id}
										label={answer.answer}
										secondary
										onClick={() => handleSubmit(answer)}
										onMouseEnter={() => {
											if (props.setChildIndex) props.setChildIndex(index);
											setHoverIndex(index);
										}}
										onMouseLeave={() => {
											if (props.setChildIndex) props.setChildIndex(null);
											setHoverIndex(false);
										}}
										style={{
											minWidth: props.scene.answers.length === 4 && '80px',
											color:
												props.project &&
												props.project.general &&
												props.project.general.buttonColor &&
												props.project.general.buttonColor.hex,
											backgroundColor:
												props.project &&
												props.project.general &&
												props.project.general.buttonBackgroundColor &&
												props.project.general.buttonBackgroundColor.hex &&
												(hoverIndex === index
													? lightenDarkenColor(props.project.general.buttonBackgroundColor.hex, -40)
													: props.project.general.buttonBackgroundColor.hex),
										}}
									/>
								</div>
							))}
					</span>
				</span>
				{props.children}
			</div>
		</React.Fragment>
	);
}

export default ContactTemplate;
