import SidebarBox from '../SidebarBox/SidebarBox';

import { useTranslation } from '../../utils/useTranslation';
import styles from './HtmlBox.module.scss';
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';

function HtmlBox(props) {
	const t = useTranslation();

	return (
		<SidebarBox>
			<div className={styles.wrapper}>
				<label className={styles.checkboxLabel}>{t.sceneTab.customHtml}</label>
				<CodeMirror
					value={props.selectedScene?.customHtml || ''}
					className={styles.codeEditor}
					name="customHtml"
					extensions={[html()]}
					placeholder={"Place your HTML code here"}
					minHeight={'120px'}
					maxWidth={'inherit'}
					basicSetup={{lineNumbers: false, foldGutter: false }}
					onChange={ userInput => {
						props.dispatch(props.updateSceneProp(props.selectedScene._id, 'customHtml', userInput));
					}}
					onBlur={() =>
						props.dispatch(
							props.updateScene(props.token, props.selectedScene._id, {
								customHtml: props.selectedScene.customHtml,
							})
						)
					}
				/>
			</div>
		</SidebarBox>
	);
}

export default HtmlBox;
