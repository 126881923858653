import { getProject } from '../actions/projectsActions';
export const GET_SCENES = 'GET_SCENES';
export const CAN_ACCESS = 'CAN_ACCESS';
export const CREATE_SCENE = 'CREATE_SCENE';
export const UPDATE_SCENE = 'UPDATE_SCENE';
export const DELETE_SCENE = 'DELETE_SCENE';
export const SCENE_ERROR = 'SCENE_ERROR';
export const SCENE_RESPONSE = 'SCENE_RESPONSE';
export const UPDATE_SCENE_PROP = 'UPDATE_SCENE_PROP';
export const ADD_SCENE_ANSWER = 'ADD_SCENE_ANSWER';
export const UPDATE_SCENE_ANSWER = 'UPDATE_SCENE_ANSWER';
export const UPDATE_SCENE_CONTACT = 'UPDATE_SCENE_CONTACT';

const apiUrl = process.env.REACT_APP_BE_URL;

/**
 * Sets scene error
 * @param {string} data
 * @returns {void}
 */
export const setSceneError = (data) => {
	return {
		type: SCENE_ERROR,
		payload: data,
	};
};

/**
 * Sets scene response
 * @param {string} response
 * @returns {void}
 */
export const setSceneResponse = (response) => {
	return {
		type: SCENE_RESPONSE,
		payload: response,
	};
};

export const fetchScenes = async (token, projectId) => {
	try {
		const res = await fetch(apiUrl + '/scenes/' + projectId, {
			method: 'GET',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * Get all scenes
 * @param {string} token
 * @param {string} projectId
 */
export const getScenes = (token, projectId) => {
	return (dispatch) => {
		fetchScenes(token, projectId).then((resJSON) => {
			if (resJSON) {
				if (resJSON.project === null) {
					dispatch({
						type: CAN_ACCESS,
						payload: resJSON.project,
					});
				}
				if (resJSON.error) {
					dispatch(setSceneError(resJSON.error));
				} else {
					dispatch({
						type: GET_SCENES,
						payload: resJSON.scenes,
					});
					if (resJSON.project) {
						dispatch(getProject(resJSON.project));
					}
				}
			} else {
				console.error(resJSON);
				dispatch(setSceneError(resJSON));
			}
		});
	};
};

export const fetchCreateScene = async (
	token,
	projectId,
	title,
	question,
	answers = [],
	template = 'Text Only',
	hasBackButton = false,
	position = {},
	imageSize = '100',
	contact = {},
	contactPro = {},
	freeText = {}
) => {
	let scene = {
		title: title,
		question: question,
		hasBackButton: hasBackButton,
		imageUrl: '',
		imageSize: imageSize,
		projectId: projectId,
		answers: answers,
		template: template,
		contact: contact,
		contactPro: contactPro,
		freeText: freeText,
	};
	if (position && position.x) {
		scene.position = position;
	}
	try {
		const res = await fetch(apiUrl + '/scenes', {
			method: 'POST',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify(scene),
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * Create scene
 * @param {string} token
 * @param {string} projectId
 * @param {string} title
 * @param {string} question
 * @param {array} answers
 * @param {string} template
 * @param {bool} hasBackButton
 * @param {string} imageSize
 */
export const createScene = ({
	token,
	projectId,
	title,
	question,
	answers = [],
	template = 'text',
	hasBackButton = false,
	position = {},
	contact = {},
	contactPro = {},
	freeText = {},
	imageSize = '100',
} = {}) => {
	return (dispatch) => {
		fetchCreateScene(
			token,
			projectId,
			title,
			question,
			answers,
			template,
			hasBackButton,
			position,
			imageSize,
			contact,
			contactPro,
			freeText
		).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setSceneError(resJSON.error));
				} else {
					dispatch(setSceneResponse(resJSON.message));
					dispatch({
						type: CREATE_SCENE,
						payload: resJSON.scene,
					});
				}
			} else {
				console.error(resJSON);
				dispatch(setSceneError(resJSON));
			}
		});
	};
};

export const fetchCopyScene = async (token, sceneId, newScenePosition) => {
	try {
		const res = await fetch(apiUrl + `/scenes?sourceId=${sceneId}`, {
			method: 'POST',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify({ position: newScenePosition }),
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

export const copySceneToCanvas = (token, sceneId, newScenePosition = {}) => {
	return (dispatch) => {
		fetchCopyScene(token, sceneId, newScenePosition).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setSceneError(resJSON.error));
				} else {
					dispatch(setSceneResponse(resJSON.message));
					dispatch({
						type: CREATE_SCENE,
						payload: resJSON.scene,
					});
				}
			} else {
				console.error(resJSON);
				dispatch(setSceneError(resJSON));
			}
		});
	};
};

export const fetchUpdateAllScenes = async (token, scenes) => {
	try {
		const res = await fetch(apiUrl + '/scenes', {
			method: 'PUT',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify(scenes),
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * Update all scenes
 * @param {string} token
 * @param {object} scenes
 */
export const updateAllScenes = (token, scenes, callback) => {
	return (dispatch) => {
		fetchUpdateAllScenes(token, scenes).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setSceneError(resJSON.error));
				} else {
					dispatch(setSceneResponse(resJSON.message));
					dispatch({
						type: GET_SCENES,
						payload: resJSON.scenes,
					});
					if (callback) callback();
				}
			} else {
				console.error(resJSON);
				dispatch(setSceneError(resJSON));
			}
		});
	};
};

export const fetchDeleteScene = async (token, sceneId) => {
	try {
		const res = await fetch(apiUrl + '/scenes/' + sceneId, {
			method: 'DELETE',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * Delete scene
 * @param {string} token auth token
 * @param {string} sceneId
 */
export const deleteScene = (token, sceneId) => {
	return (dispatch) => {
		fetchDeleteScene(token, sceneId).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setSceneError(resJSON.error));
				} else {
					dispatch(setSceneResponse(resJSON.message));
					dispatch({
						type: DELETE_SCENE,
						payload: resJSON.scenes,
					});
				}
			} else {
				console.error(resJSON);
				dispatch(setSceneError(resJSON));
			}
		});
	};
};

/**
 * Deletes video from cloudinary
 * @param {*} token auth token
 * @param {*} videoFilePublicId
 */
export const deleteCloudinaryVideo = (token, videoFilePublicId) => {
	return (dispatch) => {
		fetchDeleteCloudinaryVideo(token, videoFilePublicId).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setSceneError(resJSON.error));
				} else {
					dispatch(setSceneResponse(resJSON.message));
				}
			} else {
				console.error(resJSON);
				dispatch(setSceneError(resJSON));
			}
		});
	};
};

export const fetchDeleteCloudinaryVideo = async (token, videoFilePublicId) => {
	let encodedPublicId = encodeURIComponent(videoFilePublicId);
	try {
		const res = await fetch(apiUrl + '/scenes/delete/video/' + encodedPublicId, {
			method: 'DELETE',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * Deletes audio file from cloudinary
 * @param {*} token auth token
 * @param {*} audioFilePublicId
 */
export const deleteCloudinaryAudio = (token, audioFilePublicId) => {
	return (dispatch) => {
		fetchDeleteCloudinaryAudio(token, audioFilePublicId).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setSceneError(resJSON.error));
				} else {
					dispatch(setSceneResponse(resJSON.message));
				}
			} else {
				console.error(resJSON);
				dispatch(setSceneError(resJSON));
			}
		});
	};
};

export const fetchDeleteCloudinaryAudio = async (token, audioFilePublicId) => {
	let encodedPublicId = encodeURIComponent(audioFilePublicId);
	try {
		const res = await fetch(apiUrl + '/scenes/delete/audio/' + encodedPublicId, {
			method: 'DELETE',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

export const fetchUpdateScene = async (token, sceneId, scene) => {
	try {
		const res = await fetch(apiUrl + '/scenes/' + sceneId, {
			method: 'PUT',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify(scene),
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * Update scene
 * @param {string} token
 * @param {string} sceneId
 * @param {object} scene partial/full scene object
 */
export const updateScene = (token, sceneId, scene) => {
	return (dispatch) => {
		fetchUpdateScene(token, sceneId, scene).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setSceneError(resJSON.error));
				} else {
					dispatch(setSceneResponse(resJSON.message));
					if (scene.key)
						dispatch({
							type: UPDATE_SCENE,
							payload: { ...resJSON.scene, key: scene.key },
						});
					else
						dispatch({
							type: UPDATE_SCENE,
							payload: resJSON.scene,
						});
				}
			} else {
				console.error(resJSON);
				dispatch(setSceneError(resJSON));
			}
		});
	};
};

export const fetchUpdateAnswer = async (token, sceneId, answerIndex, answer) => {
	try {
		const res = await fetch(apiUrl + '/scenes/answer/' + sceneId, {
			method: 'PUT',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify({ answerIndex: answerIndex, answer: answer }),
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * Update scene answer
 * @param {string} token auth token
 * @param {string} sceneId
 * @param {number} answerIndex
 * @param {object} answer partial/full answer object (partial with name)
 */
export const updateAnswer = (token, sceneId, answerIndex, answer) => {
	return (dispatch) => {
		fetchUpdateAnswer(token, sceneId, answerIndex, answer).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setSceneError(resJSON.error));
				} else {
					dispatch(setSceneResponse(resJSON.message));
					dispatch({
						type: UPDATE_SCENE,
						payload: resJSON.scene,
						key: Math.random() * 100000,
					});
				}
			} else {
				console.error(resJSON);
				dispatch(setSceneError(resJSON));
			}
		});
	};
};

export const saveToStorage = (id, key, value) => {
	let tmp = { updated: new Date() };
	tmp[key] = value;
	window.localStorage.setItem(id, JSON.stringify(tmp));
	return (dispatch) => {};
};

export const fetchRemoveAnswer = async (token, sceneId, answerIndex) => {
	try {
		const res = await fetch(apiUrl + '/scenes/answer/' + sceneId, {
			method: 'DELETE',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify({ answerIndex: answerIndex }),
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * Remove scene answer
 * @param {string} token auth token
 * @param {string} sceneId
 * @param {number} answerIndex
 */
export const removeAnswer = (token, sceneId, answerIndex) => {
	return (dispatch) => {
		fetchRemoveAnswer(token, sceneId, answerIndex).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setSceneError(resJSON.error));
				} else {
					dispatch(setSceneResponse(resJSON.message));
					dispatch({
						type: UPDATE_SCENE,
						payload: resJSON.scene,
						key: Math.random() * 100000,
					});
				}
			} else {
				console.error(resJSON);
				dispatch(setSceneError(resJSON));
			}
		});
	};
};

/**
 * Delete image
 * @param {string} token auth token
 * @param {string} imageUrl
 */
export const fetchDeleteImage = async (token, imageUrl) => {
	try {
		const res = await fetch(apiUrl + '/scenes/deleteImage/', {
			method: 'DELETE',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
			body: JSON.stringify({ imageUrl: imageUrl }),
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/**
 * Send contact email
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} email
 * @param {string} toEmail
 * @param {string} phone
 * @param {string} projectName
 */
export const fetchSendContactEmail = async (
	firstName = '',
	firstNameLabel = '',
	lastName = '',
	lastNameLabel = '',
	email = '',
	emailLabel = '',
	toEmail = '',
	ccEmail = '',
	phone = '',
	phoneLabel = '',
	other = {
		name: '',
		value: '',
	},
	projectName = '',
	history = [],
	hiddenFields = []
) => {
	try {
		const res = await fetch(apiUrl + '/scene/contact', {
			method: 'POST',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
			},
			body: JSON.stringify({
				firstName: firstName,
				firstNameLabel: firstNameLabel,
				lastName: lastName,
				lastNameLabel: lastNameLabel,
				email: email,
				emailLabel: emailLabel,
				toEmail: toEmail,
				ccEmail: ccEmail,
				phone: phone,
				phoneLabel: phoneLabel,
				other: other,
				projectName: projectName,
				history: history,
				hiddenFields: hiddenFields,
			}),
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};

/* Scene FE actions */
export const updateSceneProp = (sceneId, name, value) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_SCENE_PROP,
			sceneId: sceneId,
			name: name,
			value: value,
		});
	};
};

export const addSceneAnswer = (sceneId, answer) => {
	return (dispatch) => {
		dispatch({
			type: ADD_SCENE_ANSWER,
			sceneId: sceneId,
			answer: answer,
		});
	};
};

export const updateSceneAnswer = (sceneId, answerIndex, name, value) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_SCENE_ANSWER,
			sceneId: sceneId,
			answerIndex: answerIndex,
			name: name,
			value: value,
		});
	};
};

export const fetchSendDataToWebhook = async (webhookURL, steps) => {
	let data = {
		webhookURL: webhookURL,
		steps: steps,
	};

	let sendDataToWebhook = await fetch(apiUrl + '/webhook/send/', {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-type': 'application/json',
		},
		body: JSON.stringify(data),
	});
	if (sendDataToWebhook) {
		console.log('Sending data to webhook succeded');
	} else {
		console.log('Sending data to webhook failed');
	}
};

/**
 * Deletes json file from cloudinary
 * @param {*} token auth token
 * @param {*} audioFilePublicId
 */
export const deleteCloudinaryFile = (token, jsonFilePublicId) => {
	return (dispatch) => {
		fetchDeleteCloudinaryFile(token, jsonFilePublicId).then((resJSON) => {
			if (resJSON) {
				if (resJSON.error) {
					console.error(resJSON.error);
					dispatch(setSceneError(resJSON.error));
				} else {
					dispatch(setSceneResponse(resJSON.message));
				}
			} else {
				console.error(resJSON);
				dispatch(setSceneError(resJSON));
			}
		});
	};
};

export const fetchDeleteCloudinaryFile = async (token, jsonFilePublicId) => {
	let encodedPublicId = encodeURIComponent(jsonFilePublicId);
	try {
		const res = await fetch(apiUrl + '/scenes/delete/json/' + encodedPublicId, {
			method: 'DELETE',
			mode: 'cors',
			headers: {
				Accept: 'application/json',
				'Content-type': 'application/json',
				Authorization: 'Bearer ' + token,
			},
		});
		if (res) {
			let resJSON = await res.json();
			return resJSON;
		} else {
			console.error(res);
			return undefined;
		}
	} catch (error) {
		console.error(error);
		return undefined;
	}
};