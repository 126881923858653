import { combineReducers } from 'redux';

import addTab from './addTab';
import analytics from './analytics';
import auth from './auth';
import inquiries from './inquiries';
import jsonTemplate from './jsonTemplate';
import modal from './modal';
import muteButton from './audioplayerMuteButton';
import preview from './preview';
import projects from './projects';
import scenes from './scenes';
import sessions from './sessions';
import sidebar from './sidebar';
import user from './user';
import users from './users';

const appReducer = combineReducers({
	addTab,
	analytics,
	auth,
	inquiries,
	jsonTemplate,
	modal,
	muteButton,
	preview,
	projects,
	scenes,
	sessions,
	sidebar,
	user,
	users,
});

const rootReducer = (state, action) => {
	if (action.type === 'LOGOUT_USER') {
		localStorage.clear();
	}
	return appReducer(state, action);
};

export default rootReducer;
