import { SET_TEMPLATE_TO_CREATE } from '../actions/addTabActions';

const initialState = {
	newScene: {
		templateName: null,
		clickOrDrag: ''
	},
};

export default function addTabReducer(state = initialState, action) {
	switch (action.type) {
		case SET_TEMPLATE_TO_CREATE:
			return { ...state, newScene: action.payload };
		default:
			return state;
	}
}
