import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Dashboard from '../../components/Dashboard/Dashboard';
import { getUsers, updateUsers, deleteUsers } from '../../actions/usersActions';
import { showModal, hideModal } from '../../actions/modalActions';

import styles from './Admin.module.scss';
import { ReactComponent as MinusIco } from '../../assets/icons/minus-circle.svg';
import { useTranslation } from '../../utils/useTranslation';

function Admin() {
	const t = useTranslation();
	const users = useSelector((state) => state.users.data);
	const token = useSelector((state) => state.auth.token);
	const [selectedIndex, setSelectedIndex] = useState();

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getUsers(token));
	}, [dispatch, token]);

	function deleteUser(username, email) {
		dispatch(
			showModal('POPUP_MODAL', {
				title: `${t.admin.areYouSureYouWantToDeleteUser} "${username}" ${t.admin.fromTheDataBase}`,
				buttonPrimary: {
					label: t.popup.yes,
					action: () => {
						dispatch(deleteUsers(token, email));
						dispatch(hideModal());
					},
				},
				buttonSecondary: {
					label: t.popup.no,
					action: () => {
						dispatch(hideModal());
					},
				},
			})
		);
	}

	function dateFormat(date) {
		let dateFormat = new Date(date);
		let day = dateFormat.getDate();
		let month = dateFormat.getMonth() + 1;
		let year = dateFormat.getFullYear();
		return day + '-' + month + '-' + year;
	}

	function handleRoleChange(event, user) {
		let role = event.target.id;
		dispatch(updateUsers(token, user.email, { ...user, role: role }));
	}

	//For the last two users we are showing upper dropdown because we cant see dropdown items for them
	//TODO: check how this is working when there is zero or one user
	function getDropdownPosition(index) {
		if (
			(index === users.length - 1 && users.length - 1) ||
			(index === users.length - 2 && users.length - 2) ||
			(index === users.length - 3 && users.length - 3)
		) {
			return styles.upperDropdownContent;
		}
		return styles.dropdownContent;
	}

	function handleToggleDropdownButton(index) {
		if (selectedIndex === index) {
			return styles.dropdown;
		} else {
			return styles.hideDropdown;
		}
	}

	const tableRows = users.map((user, index) => {
		return (
			<tr className={styles.tr} key={index}>
				<td className={classNames(styles.td, styles.textLeft)}>{user.email}</td>
				<td className={classNames(styles.td, styles.textLeft)}>{user.username}</td>
				<td className={styles.td}>{user.belongsTo ? user.belongsTo : '-'}</td>
				<td className={styles.td}>{dateFormat(user.created)}</td>
				<td className={styles.td}>
					{user.projectCount === 0 ? (
						0
					) : (
						<Link to={'/' + user.email + '/projectList'} className={styles.link}>
							{user.projectCount}
						</Link>
					)}
				</td>
				<td className={styles.td}>{user.projectViews}</td>
				<td className={styles.td}>
					<input
						type="checkbox"
						checked={user.active}
						onChange={() => {
							dispatch(updateUsers(token, user.email, { ...user, active: !user.active }));
						}}
					/>
				</td>
				<td
					className={styles.td}
					onMouseOver={() => setSelectedIndex(index)}
					onMouseLeave={() => setSelectedIndex(null)}
				>
					<span className={styles.td}>{user.role}</span>
					<div className={handleToggleDropdownButton(index)}>
						<span className={styles.dropbtn}></span>
						<div className={getDropdownPosition(index)}>
							<span id="admin" onClick={(event) => handleRoleChange(event, user)}>
								Admin
							</span>
							<span id="professional" onClick={(event) => handleRoleChange(event, user)}>
								Professional
							</span>
							<span id="premium" onClick={(event) => handleRoleChange(event, user)}>
								Premium
							</span>
							<span id="free" onClick={(event) => handleRoleChange(event, user)}>
								Free
							</span>
						</div>
					</div>
				</td>

				<td className={styles.td}>
					<MinusIco className={styles.minusIco} onClick={() => deleteUser(user.username, user.email)} />
				</td>
			</tr>
		);
	});

	return (
		<Dashboard>
			<div className={styles.container}>
				<h1 className={styles.title}>
					User management ({users.length})
				</h1>
				<table className={styles.table}>
					<thead>
						<tr className={styles.tr}>
							<th className={classNames(styles.th, styles.textLeft)}>Email</th>
							<th className={classNames(styles.th, styles.textLeft)}>Username</th>
							<th className={styles.th}>Belongs to</th>
							<th className={styles.th}>Registered</th>
							<th className={styles.th}>Projects</th>
							<th className={styles.th}>Project Views</th>
							<th className={styles.th}>Active</th>
							<th className={styles.th}>Role</th>
							<th className={styles.th} />
						</tr>
					</thead>
					<tbody>{tableRows}</tbody>
				</table>
			</div>
		</Dashboard>
	);
}

export default Admin;
