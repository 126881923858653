import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './JsonBox.module.scss';
import { useTranslation } from '../../utils/useTranslation';
import FileUpload from '../FileUpload/FileUpload';
import ProgressBar from '../ProgressBar/ProgressBar';
import SidebarBox from '../SidebarBox/SidebarBox';
import { ReactComponent as DoneIco } from '../../assets/icons/done.svg';
import { ReactComponent as DeleteIco } from '../../assets/icons/delete.svg';
import { showModal, hideModal } from '../../actions/modalActions';
import { deleteCloudinaryFile } from '../../actions/scenesActions';
import Input from '../Input/Input';
import RichText from '../RichText/RichText';

function JsonBox(props) {
	const t = useTranslation();
	// const dispatch = useDispatch();

	const keys = useSelector((state) => state.jsonTemplate.keys);
	const [progress, setProgress] = useState(0);
	const [isUploading, setIsUploading] = useState(false);
	const [fileName, setFileName] = useState('');
	const [foundedKey, setFoundedKey] = useState(false);

	useEffect(() => {
		let searchKey = props.selectedScene && props.selectedScene.jsonSearchKey;
		if (keys) {
			for (let key of keys) {
				if (searchKey) {
					if (searchKey.toString() === key.toString()) {
						return setFoundedKey(true);
					}
				}
			}
			setFoundedKey(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedScene.jsonSearchKey]);

	function getProgress(progress) {
		if (progress > 0 && progress < 100) {
			setIsUploading(true);
		} else {
			setIsUploading(false);
		}
		if (progress === 100) {
			setIsUploading(false);
		}
		setProgress(progress);
	}

	function getFileName(fileName) {
		setFileName(fileName);
	}
	function handleFileDelete() {
		props.dispatch(
			showModal('POPUP_MODAL', {
				title: t.jsonTemplate.fileDelete,
				buttonPrimary: {
					label: t.popup.yes,
					action: () => {
						const token = props.token;
						const scene = props.selectedScene;
						const sceneID = scene._id;
						scene.jsonFileUrl = '';
						scene.jsonFileName = '';
						scene.jsonVariablesDescripton = '';
						scene.jsonFileSource = '';
						scene.jsonSearchKey = '';
						props.dispatch(deleteCloudinaryFile(token, scene.jsonFilePublicId));
						scene.jsonFilePublicId = '';
						props.dispatch(props.updateScene(token, sceneID, scene));
						props.dispatch(hideModal());
					},
				},
				buttonSecondary: {
					label: t.popup.no,
					action: () => {
						props.dispatch(hideModal());
					},
				},
			})
		);
	}

	return (
		<SidebarBox>
			<div className={styles.inputGroup}>
				{props.selectedScene.jsonFileUrl ? (
					<div className={styles.jsonUploadedBox}>
						<span className={styles.jsonUploadedLabel}>{fileName || props.selectedScene.jsonFileName}</span>
						<DoneIco className={styles.doneIcon} />
						<DeleteIco className={styles.deleteIcon} onClick={handleFileDelete} />
					</div>
				) : (
					<div>
						<FileUpload
							dispatch={props.dispatch}
							updateScene={props.updateScene}
							updateSceneProp={props.updateSceneProp}
							token={props.token}
							selectedScene={props.selectedScene}
							scenes={props.scenes}
							getProgress={getProgress}
							isUploading={isUploading}
							getFileName={getFileName}
						/>
						{progress > 0 && progress < 100 && (
							<div className={styles.uploadingContainer}>
								<h6 className={styles.uploadFileLabel}>
									{t.jsonTemplate.uploadingFile} {parseInt(progress)}%
								</h6>
								<ProgressBar percentage={progress} />
							</div>
						)}
					</div>
				)}
			</div>
			<div className={styles.inputGroup}>
				<label className={styles.label}>{t.jsonTemplate.searchKey}</label>
				<Input
					type="text"
					name="jsonSearchKey"
					value={props.selectedScene.jsonSearchKey}
					onChange={(e) => {
						props.dispatch(props.updateSceneProp(props.selectedScene._id, 'jsonSearchKey', e.target.value));
					}}
					onBlur={() => {
						props.dispatch(
							props.updateScene(props.token, props.selectedScene._id, {
								jsonSearchKey: props.selectedScene.jsonSearchKey,
							})
						);
					}}
					secondary
					error={
						foundedKey === false && props?.selectedScene?.jsonSearchKey?.length > 0 && t.jsonTemplate.incorrectJsonKey
					}
					errorstyle={{ top: '35px', left: '15px', textAlign: 'left', fontSize: '12px' }}
				/>
			</div>
			<div className={styles.inputGroup}>
				<label className={styles.label}>{t.jsonTemplate.description}</label>
				<RichText
					selectedScene={props.selectedScene}
					defaultValue={props?.selectedScene && props.selectedScene.jsonVariablesDescripton}
					onBlur={() => {
						props.dispatch(
							props.updateScene(props.token, props.selectedScene._id, {
								jsonVariablesDescripton: props.selectedScene.jsonVariablesDescripton,
							})
						);
					}}
					onChange={(e) => {
						//We need to replace html tags because we got the <p> inside of string
						let textWithoutHTMLTags = e.replace(/<\/?[^>]+(>|$)/g, '');
						props.dispatch(
							props.updateSceneProp(props.selectedScene._id, 'jsonVariablesDescripton', textWithoutHTMLTags)
						);
					}}
					light
				/>
			</div>
			<div className={styles.inputGroup}>
				<label className={styles.label}>{t.jsonTemplate.source}</label>
				<Input
					type="text"
					name="jsonFileSource"
					value={props.selectedScene.jsonFileSource}
					onChange={(e) => {
						props.dispatch(props.updateSceneProp(props.selectedScene._id, 'jsonFileSource', e.target.value));
					}}
					onBlur={() => {
						props.dispatch(
							props.updateScene(props.token, props.selectedScene._id, {
								jsonFileSource: props.selectedScene.jsonFileSource,
							})
						);
					}}
					secondary
					errorstyle={{ fontSize: '12px' }}
				/>
			</div>
		</SidebarBox>
	);
}

export default JsonBox;
