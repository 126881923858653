import React from 'react';
import SidebarBox from '../SidebarBox/SidebarBox';
import styles from './SceneCodeSnippet.module.scss';
import { useTranslation } from '../../utils/useTranslation';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';


function CodeSnippetBox(props) {
	const t = useTranslation();
	return (
		<SidebarBox>
			<div className={styles.wrapper}>
				<div className={styles.checkboxWrapper}>
					<input
						type="checkbox"
						name="hasCodeSnippet"
						checked={props.selectedScene && props.selectedScene.hasCodeSnippet}
						onChange={(e) => {
							props.dispatch(
								props.updateScene(props.token, props.selectedScene._id, { hasCodeSnippet: e.target.checked })
							);
						}}
					/>
					<label className={styles.checkboxLabel}>{t.sceneTab.codeSnippet}</label>
				</div>
				<div>
					{props.selectedScene.hasCodeSnippet && (
						<CodeMirror
							value={props.selectedScene?.codeSnippet}
							className={styles.codeEditor}
							extensions={[javascript()]}
							basicSetup={{lineNumbers: false, foldGutter: false }}
							placeholder={"Place your code snippet here"}
							onChange={ userInput => {
								props.dispatch(props.updateSceneProp(props.selectedScene._id, 'codeSnippet', userInput));
							}}
							onBlur={() =>
								props.dispatch(
									props.updateScene(props.token, props.selectedScene._id, {
										codeSnippet: props.selectedScene.codeSnippet,
									})
								)
							}
						/>
					)}
				</div>
			</div>
		</SidebarBox>
	);
}

export default CodeSnippetBox;
