import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { setSelectedTemplate } from '../../actions/addTabActions';
import { TEMPLATE_DATA, TEMPLATE_DATA_ADMIN, TEMPLATE_DATA_PROFESSIONAL } from '../../constants/SceneConstants';
import { useTranslation } from '../../utils/useTranslation';
import styles from './AddSceneTab.module.scss';
import { ifUserRole } from '../../utils/ifUserRole';
import { ROLES } from '../../utils/constants/roles';

// Dummy data to please Mr.Blenny's FlowChartWithState onDrop data parsing function
const DUMMY_DATA_FOR_ON_DROP_FN = JSON.stringify(
	{
		type: "top/bottom",
		ports: { 
			port1: {
				id: 'port1',
				type: 'top',
			},
			port2: {
				id: 'port1',
				type: 'bottom',
			},
		} 
	}
)

const AddSceneTab = () => {
	const t = useTranslation();
	const selectedTemplate = useSelector((state) => state.addTab.templateName);
	const dispatch = useDispatch();

	let templateData = TEMPLATE_DATA;
	if (ifUserRole(ROLES.PROFESSIONAL)) templateData = TEMPLATE_DATA_PROFESSIONAL;
	if (ifUserRole(ROLES.ADMIN)) templateData = TEMPLATE_DATA_ADMIN;

	return (
		<div className={styles.addSceneTabContainer}>
			<h2 className={styles.title}>{t.addTab.title}</h2>
			{templateData.map(({ img, transKey, templateName }) => {
				return (
					<div key={templateName}>
						<img
							src={img}
							alt={transKey}
							onClick={() => dispatch(setSelectedTemplate(templateName, 'click'))}
							className={classNames(styles.image, selectedTemplate === templateName && styles.selectedTemplate)}
							draggable={true}
							onDragStart={(e) => {
								e.dataTransfer.setData('react-flow-chart', DUMMY_DATA_FOR_ON_DROP_FN)
								dispatch(setSelectedTemplate(templateName, 'drag'))
							}}
						/>
						<p className={styles.sceneTypeTitle}>{t.addTab[transKey]}</p>
					</div>
				);
			})}
		</div>
	);
};

export default AddSceneTab;
