import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { showModal, hideModal } from '../../actions/modalActions';
import { useTranslation } from '../../utils/useTranslation';

import styles from './ProjectCard.module.scss';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-proj-card.svg';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy-proj-card.svg';
import { ReactComponent as EmbedIcon } from '../../assets/icons/embed.svg';
import { ReactComponent as ShareIcon } from '../../assets/icons/share.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user-proj-card.svg';
import { ReactComponent as AnalyticsIcon } from '../../assets/icons/analytics.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/delete-proj-card.svg';
import { ReactComponent as PlayIcon } from '../../assets/icons/play.svg';
import { ifUserRole } from '../../utils/ifUserRole';
import { isAuthor } from '../../utils/isAuthor';
import { fetchProjects } from '../../actions/projectsActions';
import { copyToClipboard } from '../../utils/copyToClipboard';
import { ROLES } from '../../utils/constants/roles';
import { getEmbedCode } from '../../utils/projectHelpers';
import { checkForFirstScene } from '../../utils/checkForFirstScene';

const WIDGET_PREVIEW_URL = process.env.REACT_APP_PREVIEW_URL

// called by ProjectList.js
function ProjectCard(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const t = useTranslation();
	const token = useSelector((state) => state.auth.token);
	const user = useSelector((state) => state.user);

	const embedCode = getEmbedCode(props._id);
	// const embedCode = `
	// 	<div style="position: relative;
	// 							overflow: hidden;
	// 							width: 100%;
	// 							padding-top: 56.25%;
	// 						"
	// 	>
	// 		<iframe style="position: absolute;
	// 									top: 0;
	// 									left: 0;
	// 									bottom: 0;
	// 									right: 0;
	// 									width: 100%;
	// 									height: 100%;
	// 									border: none;
	// 									"
	// 				src="${window.location.href.replace('projectList', 'previewPublic')}/${props._id}/widget"
	// 				title="${props._id.toString()}"
	// 		></iframe>
	// 	</div>
	// `;

	const showEditIcon = ifUserRole(ROLES.ADMIN) || isAuthor(props.beraterCreatorId);
	const showCopyIcon = ifUserRole(ROLES.ADMIN) || isAuthor(props.beraterCreatorId);
	const showEmbedIcon = ifUserRole(ROLES.ADMIN) || isAuthor(props.beraterCreatorId);
	const showShareIcon = ifUserRole(ROLES.ADMIN) || isAuthor(props.beraterCreatorId);
	const showInquiryIcon = (ifUserRole(ROLES.ADMIN) || isAuthor(props.beraterCreatorId)) && props.saveInquiriesToDB;
	const showAnalyticsIcon =  ifUserRole(ROLES.ADMIN) ||  (ifUserRole(ROLES.PROFESSIONAL) && isAuthor(props.beraterCreatorId));
	const showDeleteIcon = ifUserRole(ROLES.ADMIN) || isAuthor(props.beraterCreatorId);

	const editProject = (id) => {
		if (window.innerWidth < 992) {
			props.showPopup();
		} else {
			history.push('/sceneView/' + id);
		}
	};

	const copyProject = () => {

		fetchProjects(token).then((resJson) => {
			if (resJson.error) {
				console.log('error', resJson.error);
			} else {
				if (resJson.projects.length < 3 || (user && ifUserRole(ROLES.ADMIN, ROLES.PROFESSIONAL))) {
					props.copyProject();
					showPopup(t.projectList.pleaseWaitForCopy, "");
				} else {
					showPopup(t.projectList.maxNumberOf3, t.projectList.contactSupport);
				}
			}
		});
	};
	function showPopup(title, subtitle) {
		dispatch(
			showModal('POPUP_MODAL', {
				title: title,
				subtitle: subtitle,
				buttonPrimary: {
					label: t.popup.ok,
					action: () => {
						dispatch(hideModal());
					},
				},
			})
		);
	}

	function deleteProject() {
		props.dispatch(
			showModal('POPUP_MODAL', {
				title: t.projectList.confirmDelete,
				buttonPrimary: {
					label: t.popup.yes,
					action: () => {
						props.deleteProject();
						props.dispatch(hideModal());
						showPopup(t.projectList.pleaseWaitForDeletion, "");
					},
				},
				buttonSecondary: {
					label: t.popup.no,
					action: () => {
						props.dispatch(hideModal());
					},
				},
			})
		);
	}

	return (
		<div className={styles.card}>
			<div className={styles.header}>
				<h1 className={styles.title}>{props.title}</h1>
			</div>
			{/* TODO please check if this is still needed.
			{ifUserRole(ROLES.ADMIN) && (
				<span className={styles.viewCount}>
					{t.projectList.viewsLabel} - {props.views}
				</span>
			)} */}
			<p className={styles.description}>
				{props.description.length > 170 ? props.description.slice(0, 167) + '...' : props.description}
			</p>
			<div className={styles.iconsRow}>
				{showEditIcon && <EditIcon className={styles.editIcon} onClick={() => editProject(props._id)} title={t.projectList.editProject} />}
				{showCopyIcon && <CopyIcon className={styles.copyIcon} onClick={() => copyProject(props._id)} title={t.projectList.copyProject} />}
				{showEmbedIcon && (
					<EmbedIcon className={styles.embedIcon} onClick={() => copyToClipboard(embedCode, showPopup, t.popup.codeCopiedToClipboard)} title={t.preview.copyEmbedCode} />
				)}
				{showShareIcon && (
					<ShareIcon
						className={styles.shareIcon}
						onClick={() => copyToClipboard(`${WIDGET_PREVIEW_URL}/berater/${props._id}`, showPopup, t.popup.linkCopiedToClipboard)}
						title={t.projectList.shareProjectURL}
					/>
				)}
				{showInquiryIcon && (
					<UserIcon className={styles.userIcon} onClick={() => history.push(`/inquiries/${props._id}`)} title={t.projectList.viewInquiries} />
				)}
				{showAnalyticsIcon && (
					<AnalyticsIcon className={styles.analyticsIcon} onClick={() => history.push(`/sessions/${props._id}`)} title={t.projectList.viewSessions} />
				)}
				{showDeleteIcon && <DeleteIcon className={styles.deleteIcon} onClick={deleteProject} title={t.projectList.deleteProject} />}
			</div>
			<div className={styles.imageContainer}>
				{props.projectImage && <img className={styles.image} src={props.projectImage} alt="Project background" />}
				<PlayIcon
					className={styles.playIcon}
					alt="Play placeholder"
					onClick={async () => {  //previously: history.push(WIDGET_PREVIEW_URL + props._id)
						const check = await checkForFirstScene('', dispatch, t, token, props._id);
						check && window.open(`${WIDGET_PREVIEW_URL}/berater/${props._id}`, '_blank');
					}}
				/>
			</div>
		</div>
	);
}

export default ProjectCard;
