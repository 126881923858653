import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';

import SelectInput from '../../components/SelectInput/SelectInput';
import { logoutUser } from '../../actions/authActions';
import { useTranslation } from '../../utils/useTranslation';

import styles from './Navbar.module.scss';
import logo from '../../assets/icons/logo.svg';
import userIco from '../../assets/icons/user-circle.svg';

import { ifUserRole } from '../../utils/ifUserRole';
import { ROLES } from '../../utils/constants/roles';

const apiUrl = process.env.REACT_APP_BE_URL;

/**
 * Sends userID to the backend
 * Backend is going to sent back redirect URL with autogenerated token
 */
async function sendUserId() {
	let userID = localStorage.getItem('id');
	if (userID) {
		try {
			const getLoginURL = await fetch(apiUrl + `/users/paymentLogin/${userID}`, {
				method: 'GET',
				mode: 'cors',
				headers: {
					Accept: 'application/json',
					'Content-type': 'application/json',
				},
			});
			if (getLoginURL) {
				let redirectURL = await getLoginURL.json();
				window.location.href = redirectURL;
			}
		} catch (error) {
			console.log('Something went wrong. Something: ', error);
		}
	} else {
		console.log('Something went wrong with userID');
	}
}

function Navbar() {
	const t = useTranslation();
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const username = localStorage.getItem('username');
	// const user = useSelector((state) => state.user);  comment to remove warning

	const token = useSelector((state) => state.auth.token);

	return (
		<div className={styles.navbar}>
			<img
				src={logo}
				alt="logo"
				className={styles.logo}
				onClick={() => {
					if (token) return history.push('/projectList');
					else return history.push('/login');
				}}
			/>
			<ul className={styles.list}>
				<li className={styles.item} onClick={() => history.push('/ersteschritte')}>
					{t.navbar.firstSteps}
				</li>
				<li
					className={classNames(styles.item, { [styles.active]: location.pathname === '/projectList' })}
					onClick={() => history.push('/projectList')}
				>
					{t.navbar.myBerater}
				</li>
				<li className={styles.item} onClick={() => history.push('/downloads')}>
					{t.navbar.downloads}
				</li>

				<li className={styles.item}>
					<img src={userIco} alt="userIcon" className={styles.userIcon} />
					<SelectInput
						options={
							ifUserRole(ROLES.ADMIN)
								? [
										{
											label: t.navbar.mySubscriptions,
											onClick: () => {
												sendUserId();
											},
										},
										{
											label: t.navbar.admin,
											onClick: () => {
												history.push('/admin');
											},
										},
										{
											label: t.navbar.logout,
											onClick: () => {
												dispatch(logoutUser(token));
											},
										},
								  ]
								: [
										{
											label: t.navbar.mySubscriptions,
											onClick: () => {
												sendUserId();
											},
										},
										{
											label: t.navbar.logout,
											onClick: () => {
												dispatch(logoutUser(token));
											},
										},
								  ]
						}
						selected={username}
						navbar
					/>
				</li>
			</ul>
		</div>
	);
}

export default Navbar;
