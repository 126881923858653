import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal, showModal } from '../../actions/modalActions';

import SceneBox from '../../components/SceneBox/SceneBox';
import AnswersBox from '../../components/AnswersBox/AnswersBox';
import ImageTextBox from '../../components/ImageTextBox/ImageTextBox';
import AudioBox from '../../components/AudioBox/AudioBox';
import VideoBox from '../../components/VideoBox/VideoBox';
import ContactBox from '../../components/ContactBox/ContactBox';
import ContactProBox from '../../components/ContactProBox/ContactProBox';
import JsonBox from '../../components/JsonBox/JsonBox';
import CodeSnippetBox from '../../components/SceneCodeSnippet/SceneCodeSnippet';
import CustomCssBox from '../../components/CustomCSSBox/CustomCSSBox';
import FooterBox from '../../components/FooterBox/FooterBox';
import { updateAllScenes, updateScene, deleteScene, updateSceneProp } from '../../actions/scenesActions';
import { useTranslation } from '../../utils/useTranslation';

import styles from './SceneTab.module.scss';
import { ifUserRole } from '../../utils/ifUserRole';
import { ROLES } from '../../utils/constants/roles';
import HtmlBox from '../../components/HtmlBox/HtmlBox';
import { checkForFirstScene } from '../../utils/checkForFirstScene';
import FreeTextBox from '../../components/FreeTextBox/FreeTextBox';


function SceneTab(props) {
	const t = useTranslation();
	const dispatch = useDispatch();
	const token = useSelector((state) => state.auth.token);
	const scenes = useSelector((state) => state.scenes.data);
	const selectedScene = props.selectedSceneId && scenes.filter((scene) => scene._id === props.selectedSceneId)[0];
	const response = useSelector((state) => state.scenes.response);
	const [warning, setWarning] = useState(false);

	function renderBackButton() {
		return (
			<div className={styles.checkboxWrapper}>
				<input
					type="checkbox"
					name="hasBackButton"
					checked={selectedScene && selectedScene.hasBackButton}
					onChange={(e) => {
						dispatch(updateScene(token, selectedScene._id, { hasBackButton: e.target.checked }));
					}}
				/>
				<label className={styles.checkboxLabel}>{t.sceneTab.backButton}</label>
			</div>
		);
	}

	return (
		<React.Fragment>
			<SceneBox  //renders the dropdown, the question and the first-scene checkbox
				token={token}
				selectedScene={selectedScene}
				scenes={scenes}
				dispatch={dispatch}
				updateScene={updateScene}
				updateSceneProp={updateSceneProp}
			/>
			{(selectedScene.template === 'text_image' ||
				selectedScene.template === 'images' ||
				selectedScene.template === 'text' ||
				selectedScene.template === 'json') && (
				<ImageTextBox  //renders 'add picture', upload + conditions, alt meta tag for 'text_image' templates, 
					dispatch={dispatch}  // and scene description and price for all 4 templates.
					updateScene={updateScene}
					token={token}
					selectedScene={selectedScene}
					updateSceneProp={updateSceneProp}
				/>
			)}
			{selectedScene &&
				(selectedScene.template === 'text' ||
				selectedScene.template === 'text_image' ||
				selectedScene.template === 'images' ||
				selectedScene.template === 'columns' ||
				selectedScene.template === 'freeText') && (
					<AudioBox
						dispatch={dispatch}
						updateScene={updateScene}
						token={token}
						selectedScene={selectedScene}
						updateSceneProp={updateSceneProp}
						autoplay={false}
					/>
			)}
			{selectedScene && selectedScene.template === 'video' && (
				<VideoBox
					dispatch={dispatch}
					updateScene={updateScene}
					token={token}
					selectedScene={selectedScene}
					updateSceneProp={updateSceneProp}
					autoplay={false}
				/>
			)}
			{selectedScene && 
				selectedScene.template !== 'contact' && 
				selectedScene.template !== 'contactPro' && 
				selectedScene.template !== 'json' && 
				selectedScene.template !== 'freeText' && (
					<AnswersBox
						dispatch={dispatch}
						updateScene={updateScene}
						token={token}
						selectedScene={selectedScene}
						scenes={scenes}
					/>
			)}
			{selectedScene.template === 'contact' && (
				<ContactBox
					dispatch={dispatch}
					updateScene={updateScene}
					updateSceneProp={updateSceneProp}
					token={token}
					selectedScene={selectedScene}
					scenes={scenes}
				/>
			)}
			{ifUserRole(ROLES.ADMIN, ROLES.PROFESSIONAL) &&
				selectedScene.template === 'contactPro' && (
					<ContactProBox
						dispatch={dispatch}
						updateScene={updateScene}
						updateSceneProp={updateSceneProp}
						token={token}
						selectedScene={selectedScene}
						scenes={scenes}
					/>
			)}
			{selectedScene.template === 'json' && (
				<JsonBox
					dispatch={dispatch}
					updateScene={updateScene}
					updateSceneProp={updateSceneProp}
					token={token}
					selectedScene={selectedScene}
					scenes={scenes}
				/>
			)}
			{ifUserRole(ROLES.ADMIN, ROLES.PROFESSIONAL) &&
				selectedScene.template === 'freeText' && (
					<FreeTextBox
						dispatch={dispatch}
						updateScene={updateScene}
						updateSceneProp={updateSceneProp}
						token={token}
						selectedScene={selectedScene}
						scenes={scenes}
					/>
			)}
			{ifUserRole(ROLES.ADMIN) &&
				selectedScene.template === 'html' && (
					<HtmlBox
						dispatch={dispatch}
						updateScene={updateScene}
						updateSceneProp={updateSceneProp}
						token={token}
						selectedScene={selectedScene}
						scenes={scenes}
					/>
			)}
			{ifUserRole(ROLES.ADMIN, ROLES.PROFESSIONAL) && (
				<CustomCssBox
					dispatch={dispatch}
					scope={'scene'}
					updateSceneOrProject={updateScene}
					token={token}
					selectedSceneOrProject={selectedScene}
					updateSceneOrProjectProp={updateSceneProp}
				/>
			)}
			{ifUserRole(ROLES.ADMIN, ROLES.PROFESSIONAL) && (
				<CodeSnippetBox
					dispatch={dispatch}
					updateScene={updateScene}
					token={token}
					selectedScene={selectedScene}
					updateSceneProp={updateSceneProp}
				/>
			)}

			<FooterBox
				linkLabel={t.sceneTab.deleteScene}
				onLinkClick={() => {
					dispatch(
						showModal('POPUP_MODAL', {
							title: t.sceneTab.confirmDelete,
							buttonPrimary: {
								label: t.popup.yes,
								action: () => {
									dispatch(deleteScene(token, selectedScene._id));
									props.setSelectedSceneId('');
									dispatch(hideModal());
								},
							},
							buttonSecondary: {
								label: t.popup.no,
								action: () => {
									dispatch(hideModal());
								},
							},
						})
					);
				}}
				buttonLabel={t.sceneTab.save}
				onButtonClick={() => {
					dispatch(updateAllScenes(token, { scenes: scenes }));
					props.setSelectedSceneId('');
					setWarning(true);
					checkForFirstScene(scenes, dispatch, t);					  
				}}
				backButton={renderBackButton()}
				warning={warning && response}
			/>
		</React.Fragment>
	);
}

export default SceneTab;