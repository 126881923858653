import classNames from 'classnames';

import styles from './Toggle.module.scss';

function Toggle(props) {
	return (
		<label className={styles.switch}>
			<input type="checkbox" checked={props.checked} onChange={() => props.onChange()} />
			<span className={classNames(styles.slider, styles.round)}></span>
		</label>
	);
}

export default Toggle;
